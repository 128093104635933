import * as React from 'react'
import { Input } from '@interaction-gaming/mslc-charitable-gaming-components'
import { getBlockClass } from 'src/helpers/components'
import { ButtonPrimary } from '../button-primary/button-primary-ui'
import './styles.scss'

export type PermitHolderSearchBoxProps = {
  onSearch: (searchTerm?: string) => Promise<void>
  defaultSearchTerm?: string
}

const ROOT_CLASS = 'permit-holder-search-box'

export const PermitHolderSearchBox = (props: PermitHolderSearchBoxProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  return (
    <div className={ROOT_CLASS}>
      <p>Enter your 6-digit Organization ID to proceed.</p>
      <form
        onSubmit={(e) => {
          // prevent page reload
          e.preventDefault()
          props.onSearch(inputRef.current?.value)
        }}
      >
        <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
          <Input
            type="text"
            name="search"
            placeholder="123456"
            ref={inputRef}
            className={getBlockClass(ROOT_CLASS, 'input')}
            defaultValue={props.defaultSearchTerm}
          />
          <ButtonPrimary type="submit">Search</ButtonPrimary>
        </div>
      </form>
    </div>
  )
}
