import * as React from 'react'
import {
  Button,
  Input,
  InputRadio,
  useFormPlus
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { getBlockClass } from 'src/helpers/components'
import { InputTextarea } from '../input-textarea/input-textarea-ui'
import { ValidationErrorMessage } from '../validation-error-message/validation-error-message-ui'
import { LoadingIndicator } from '../loading-indicator/loading-indicator-ui'
import { getRequiredIfYesValidation } from './validators'
import {
  AnythingToReportField,
  AnythingToReportFormData,
  AnythingToReportValue
} from './constants'
import './styles.scss'

export type Props = {
  onSubmit: (formData: AnythingToReportFormData) => void
  loading: boolean
}

const ROOT_CLASS = 'anything-to-report'
const FORM_ID = `${ROOT_CLASS}-form`
const OPTION_CLASS = getBlockClass(ROOT_CLASS, 'option')
const RADIO_CLASS = getBlockClass(ROOT_CLASS, 'radio')

export const AnythingToReport: React.FC<Props> = (props) => {
  const {
    register,
    registerRequired,
    registerEmail,
    handleSubmit,
    watch,
    getValues,
    trigger,
    formState: { errors }
  } = useFormPlus<AnythingToReportFormData>()
  const checkedValue = watch(AnythingToReportField.yesOrNo)

  React.useEffect(() => {
    if (checkedValue === AnythingToReportValue.yes) {
      trigger(AnythingToReportField.noGameReason)
      trigger(AnythingToReportField.signature)
      trigger(AnythingToReportField.email)
    }
  }, [checkedValue, trigger])

  const yesOrNoError = errors[AnythingToReportField.yesOrNo]
  const noGameReasonError = errors[AnythingToReportField.noGameReason]

  return (
    <div className={getBlockClass(ROOT_CLASS, 'container')}>
      <div className={ROOT_CLASS}>
        <h2>
          Do you have any items to report this week (sales, expenses, rent)?
        </h2>
        <form onSubmit={handleSubmit(props.onSubmit)} id={FORM_ID}>
          <div className={getBlockClass(ROOT_CLASS, 'radios')}>
            <InputRadio
              {...registerRequired('Yes or No', AnythingToReportField.yesOrNo)}
              value={AnythingToReportValue.yes}
              id={`${AnythingToReportField}-${AnythingToReportValue.yes}`}
              checked={checkedValue === AnythingToReportValue.yes}
              className={RADIO_CLASS}
            >
              <p className={OPTION_CLASS}>
                <b>Yes,</b> continue to form
              </p>
            </InputRadio>
            <InputRadio
              {...registerRequired('Yes or No', AnythingToReportField.yesOrNo)}
              value={AnythingToReportValue.no}
              id={`${AnythingToReportField}-${AnythingToReportValue.no}`}
              checked={checkedValue === AnythingToReportValue.no}
              className={RADIO_CLASS}
            >
              <p className={OPTION_CLASS}>
                <b>No</b> (if there was no game, provide reason and contact info
                below)
              </p>
            </InputRadio>
            {yesOrNoError && (
              <ValidationErrorMessage>
                {yesOrNoError?.message}
              </ValidationErrorMessage>
            )}
          </div>
          <InputTextarea
            {...register(
              AnythingToReportField.noGameReason,
              getRequiredIfYesValidation(
                getValues,
                AnythingToReportField.noGameReason
              )
            )}
            error={noGameReasonError?.message ? true : false}
            className={getBlockClass(ROOT_CLASS, 'no-game-reason')}
          />
          {noGameReasonError && (
            <ValidationErrorMessage>
              {noGameReasonError?.message}
            </ValidationErrorMessage>
          )}
          <div className={getBlockClass(ROOT_CLASS, 'contact-fields')}>
            <Input
              {...registerEmail(
                'Email',
                AnythingToReportField.email,
                getRequiredIfYesValidation(
                  getValues,
                  AnythingToReportField.email
                )
              )}
              placeholder="johnsmith@email.com"
              error={errors[AnythingToReportField.email]?.message}
            />
            <Input
              {...register(
                AnythingToReportField.signature,
                getRequiredIfYesValidation(
                  getValues,
                  AnythingToReportField.signature
                )
              )}
              labelText="Signature"
              error={errors[AnythingToReportField.signature]?.message}
            />
          </div>
        </form>
      </div>
      {props.loading ? (
        <LoadingIndicator />
      ) : (
        <Button
          htmlProps={{
            type: 'submit',
            form: FORM_ID
          }}
        >
          Next
        </Button>
      )}
    </div>
  )
}
