import * as React from 'react'
import { ErrorMessage } from 'src/components/error-message/error-message-ui'
import { getBlockClass } from 'src/helpers/components'
import {
  WeeklyBeanoPrintLink,
  Props as PrintLinkProps
} from './weekly-beano-print-link-ui'
import './styles.scss'

export type Props = PrintLinkProps

export const ROOT_CLASS = 'weekly-beano-submission-failure'

export const WeeklyBeanoSubmissionFailure = (props: Props) => {
  return (
    <div className={ROOT_CLASS}>
      <ErrorMessage type="Try Again Later" />
      <div className={getBlockClass(ROOT_CLASS, 'text')}>
        Click <WeeklyBeanoPrintLink {...props} /> to print a copy of this form
        for your files.
      </div>
    </div>
  )
}
