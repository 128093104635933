import * as React from 'react'
import { Button } from '@interaction-gaming/mslc-charitable-gaming-components'
import './styles.scss'

type Props = {
  type: 'submit' | 'reset' | 'button'
  onClick?: () => unknown
}

const ROOT_CLASS = 'button-primary'

export const ButtonPrimary: React.FC<Props> = (props) => {
  return (
    <Button
      htmlProps={{
        type: props.type,
        onClick: props.onClick
      }}
      className={ROOT_CLASS}
    >
      {props.children}
    </Button>
  )
}
