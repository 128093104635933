import * as React from 'react'
import { IReactToPrintProps, useReactToPrint } from 'react-to-print'

export const usePrint = <T extends HTMLElement>(
  props: Omit<IReactToPrintProps, 'content'>,
  printRef: React.RefObject<T>
) => {
  const handlePrint = useReactToPrint({
    ...props,
    content: () => printRef?.current
  })
  return handlePrint
}
