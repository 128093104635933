import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BeanoFormType,
  UnsubmittedForm
} from 'src/api/charitable-gaming/api-response-types'
import { ROUTES } from 'src/helpers/constants'
import { useAppDispatch, useAppSelector } from 'src/hooks/custom-redux-hooks'
import { useFetchUnsubmittedForms } from 'src/hooks/use-fetch-unsubmitted-forms'
import { BeanoActions } from 'src/reducers/beano-form/beano-form-reducer'
import { LicenseeSearchPage } from './licensee-search-page-ui'

const noLicenseeError = 'Licensee not found'

export const LicenseeSearchPageContainer = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { activeForm } = useAppSelector((state) => state.beano)

  const { handleSearch, data, error, loading, fetched } =
    useFetchUnsubmittedForms()
  const onContinueClick = (form: UnsubmittedForm) => {
    dispatch(BeanoActions.setActiveForm(form))
    if (form.formType === BeanoFormType.weekly) {
      navigate(ROUTES.weeklyBeano)
    } else if (form.formType === BeanoFormType.annual) {
      navigate(ROUTES.annualBeano)
    }
  }
  const noResults = error === noLicenseeError
  return (
    <LicenseeSearchPage
      loading={loading}
      noFormsReady={fetched && !error}
      error={noResults ? undefined : error}
      noResults={noResults}
      forms={data?.forms ?? []}
      onSearch={handleSearch}
      onContinueClick={onContinueClick}
      defaultSearchTerm={activeForm?.licenseNumber}
    />
  )
}
