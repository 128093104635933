import { configureStore } from '@reduxjs/toolkit'
import { beanoSlice } from './beano-form/beano-form-reducer'
import { permitHolderSlice } from './permit-holder/permit-holder-reducer'

export const store = configureStore({
  reducer: {
    beano: beanoSlice.reducer,
    permitHolder: permitHolderSlice.reducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
