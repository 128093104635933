import * as React from 'react'
import {
  CasinoFormPrintProps,
  CasinoFormPrint
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { PrintLink } from 'src/components/print-link/print-link-ui'
import './styles.scss'

export type CasinoPrintLinkProps = CasinoFormPrintProps

export const CasinoPrintLink = (props: CasinoPrintLinkProps) => (
  <PrintLink
    PrintElement={
      <CasinoFormPrint
        formData={props.formData}
        organizationDetails={props.organizationDetails}
      />
    }
    title={`Casino Form | ${props.organizationDetails.organizationName} - ${props.formData.occasionDate}`}
  />
)
