import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PermitHolderForm, ROUTES } from 'src/helpers/constants'
import { Optional, ValueOf } from 'src/helpers/util'
import { useAppSelector } from 'src/hooks/custom-redux-hooks'
import { useAssertPermitHolderFormPath } from 'src/hooks/use-assert-permit-holder-form'
import { useFetchPermitHolder } from 'src/hooks/use-fetch-permit-holder'
import { PermitHolderActions } from 'src/reducers/permit-holder/permit-holder-reducer'
import { PermitHolderSelectors } from 'src/reducers/permit-holder/permit-holder-selectors'
import { PermitHolderSearchPage } from './permit-holder-search-page-ui'

const getPermitHolderFormRoute = (
  formToOpen?: PermitHolderForm
): Optional<ValueOf<typeof ROUTES>> => {
  switch (formToOpen) {
    case PermitHolderForm.casino:
      return ROUTES.casinoReport
    case PermitHolderForm.raffle:
      return ROUTES.raffleReport
    default:
      return undefined
  }
}

export const PermitHolderSearchPageContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { handleSearch, loading, error, data, noResults } =
    useFetchPermitHolder()
  const { pathFormType } = useAssertPermitHolderFormPath()
  const { permitHolder, formType } = useAppSelector(PermitHolderSelectors.state)
  const formRoute = getPermitHolderFormRoute(pathFormType)

  React.useEffect(() => {
    if (formRoute && pathFormType && !error && data) {
      dispatch(
        PermitHolderActions.setActivePermitHolder({
          formType: pathFormType,
          permitHolder: data.permit
        })
      )
      navigate(formRoute)
    }
  }, [formRoute, pathFormType, data, error, navigate, dispatch])

  if (!pathFormType) {
    return null
  }

  const defaultSearchTerm =
    formType === pathFormType ? permitHolder?.raffleId : undefined
  return (
    <PermitHolderSearchPage
      onSearch={handleSearch}
      error={error}
      loading={loading}
      noResults={noResults}
      defaultSearchTerm={defaultSearchTerm}
      formType={pathFormType}
    />
  )
}
