export enum CharitableGamingApiVersion {
  v1 = 'v1'
}

export const getVersionedCharitableGamingEndpoint = (
  apiVersion: CharitableGamingApiVersion
) => {
  return `${process.env.REACT_APP_BACKEND_CG_API_ENDPOINT}/${apiVersion}`
}

export const getHealthCheckUrl = (
  apiVersion: CharitableGamingApiVersion = CharitableGamingApiVersion.v1
) => `${getVersionedCharitableGamingEndpoint(apiVersion)}/healthcheck`

export const getUnsubmittedFormsUrl = (
  apiVersion: CharitableGamingApiVersion = CharitableGamingApiVersion.v1
) =>
  `${getVersionedCharitableGamingEndpoint(
    apiVersion
  )}/unsubmitted-tax-form-search`

export const getPermitHolderSearchUrl = (
  permitId: string,
  apiVersion: CharitableGamingApiVersion = CharitableGamingApiVersion.v1
) =>
  `${getVersionedCharitableGamingEndpoint(
    apiVersion
  )}/permit-holder/${permitId}`

export const getSubmitWeeklyBeanoUrl = (
  apiVersion: CharitableGamingApiVersion = CharitableGamingApiVersion.v1
) => `${getVersionedCharitableGamingEndpoint(apiVersion)}/weekly-beano-tax-form`

export const getSubmitAnnualBeanoUrl = (
  apiVersion: CharitableGamingApiVersion = CharitableGamingApiVersion.v1
) => `${getVersionedCharitableGamingEndpoint(apiVersion)}/annual-beano-tax-form`

export const getSubmitRaffleReportUrl = (
  apiVersion: CharitableGamingApiVersion = CharitableGamingApiVersion.v1
) =>
  `${getVersionedCharitableGamingEndpoint(apiVersion)}/raffle-bazaar-tax-form`

export const getSubmitCasinoReportUrl = (
  apiVersion: CharitableGamingApiVersion = CharitableGamingApiVersion.v1
) =>
  `${getVersionedCharitableGamingEndpoint(apiVersion)}/casino-function-tax-form`
