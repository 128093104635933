import * as React from 'react'
import {
  WeeklyBeanoForm,
  WeeklyBeanoFormBodySubmission,
  WeeklyBeanoFormSubmission
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { AnythingToReport } from 'src/components/anything-to-report/anything-to-report-ui'
import { AnythingToReportFormData } from 'src/components/anything-to-report/constants'
import { Breadcrumbs } from 'src/components/breadcrumb/constants'
import { Page } from 'src/components/page/page-ui'
import { WeeklyBeanoReportSubmitted } from './report-submitted-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from 'src/helpers/components'
import { LoadingIndicator } from 'src/components/loading-indicator/loading-indicator-ui'
import { UnsubmittedForm } from 'src/api/charitable-gaming/api-response-types'
import { WeeklyBeanoSubmissionFailure } from './complete-submission-failure-ui'
import {
  getFormattedDateFromForm,
  getOrgDetailsFromForm
} from 'src/api/charitable-gaming/helpers'
import './styles.scss'

export type Props = {
  showFullForm: boolean
  loading: boolean
  error?: string
  completeSubmissionFailure: boolean
  submitted: boolean
  submittedData?: WeeklyBeanoFormSubmission
  form: UnsubmittedForm
  onSubmitFullForm: (data: WeeklyBeanoFormBodySubmission) => Promise<void>
  onSubmitReportForm: (data: AnythingToReportFormData) => Promise<void>
}

const ROOT_CLASS = 'weekly-beano-ui'

export const WeeklyBeanoPage: React.FC<Props> = (props) => {
  const { orgDetails, formattedDate } = React.useMemo(
    () => ({
      orgDetails: getOrgDetailsFromForm(props.form),
      formattedDate: getFormattedDateFromForm(props.form)
    }),
    [props.form]
  )

  const getPreOrPostSubmissionContents = () => {
    if (props.completeSubmissionFailure && props.submittedData) {
      return (
        <WeeklyBeanoSubmissionFailure
          organizationDetails={orgDetails}
          dateString={formattedDate}
          formData={props.submittedData}
          occasionTime={props.form.occasionTime}
        />
      )
    } else if (props.submitted && props.submittedData) {
      return (
        <WeeklyBeanoReportSubmitted
          fullForm={props.showFullForm}
          occasionDate={props.form.occasionDate}
          organizationDetails={orgDetails}
          dateString={formattedDate}
          formData={props.submittedData}
          occasionTime={props.form.occasionTime}
        />
      )
    } else if (!props.showFullForm) {
      return (
        <AnythingToReport
          onSubmit={props.onSubmitReportForm}
          loading={props.loading}
        />
      )
    }
  }

  const preOrPostSubmissionContents = getPreOrPostSubmissionContents()
  return (
    <Page
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'full-form', props.showFullForm)
      ])}
      headerText={Breadcrumbs.weeklyBeano.text}
      breadcrumb={Breadcrumbs.weeklyBeano}
    >
      {preOrPostSubmissionContents ?? (
        <div className={getBlockClass(ROOT_CLASS, 'form-container')}>
          <WeeklyBeanoForm
            dateString={formattedDate}
            organizationDetails={orgDetails}
            error={Boolean(props.error)}
            onSubmit={props.onSubmitFullForm}
            submitDisabled={props.loading}
            occasionTime={props.form.occasionTime}
          />
          {props.loading && <LoadingIndicator />}
        </div>
      )}
    </Page>
  )
}
