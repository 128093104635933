import * as React from 'react'
import { Button } from '@interaction-gaming/mslc-charitable-gaming-components'
import { Caret } from 'src/assets/caret'
import './styles.scss'

type Props = {
  onClick: () => unknown
}

const ROOT_CLASS = 'button-continue'

export const ButtonContinue: React.FC<Props> = (props) => (
  <Button className={ROOT_CLASS} htmlProps={{ onClick: props.onClick }}>
    <span>{props.children}</span>
    <Caret />
  </Button>
)
