import {
  WeeklyBeanoFormBody,
  WeeklyBeanoNoGameSubmission
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { AnythingToReportFormData } from 'src/components/anything-to-report/constants'

export const defaultWeeklyBeanoReport: WeeklyBeanoFormBody = {
  occasionDate: '',
  occasionDay: '',
  licenseNumber: '',
  grossBeanoReceiptsInCents: 0,
  totalBeanoExpendituresInCents: 0,
  netProfitInCents: 0,
  fundsAvailableForDisbursementInCents: 0,
  totalDisbursementsInCents: 0,
  unexpendedNetProfitInCents: 0,
  totalDepositForOccasionInCents: 0
}

export const getNoGameWeeklyBeanoReport = (
  formData: AnythingToReportFormData,
  occasionDate: string,
  occasionDay: string,
  licenseNumber: string
): WeeklyBeanoNoGameSubmission => ({
  ...defaultWeeklyBeanoReport,
  occasionDate,
  occasionDay,
  licenseNumber,
  noGameDetails: {
    reason: formData.noGameReason,
    signature: formData.signature,
    email: formData.email
  }
})
