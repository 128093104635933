import { ROUTES } from 'src/helpers/constants'
import { BreadcrumbProps } from './types'

const homeBreadcrumb: BreadcrumbProps = {
  path: ROUTES.home,
  text: 'Home'
}

const licenseeSearchBreadcrumb: BreadcrumbProps = {
  path: ROUTES.licenseeSearch,
  text: 'Licensee Search',
  parent: homeBreadcrumb
}

const permitHolderSearchBreadcrumb: BreadcrumbProps = {
  path: ROUTES.permitHolderSearchBase,
  text: 'Permit Holder Search',
  parent: homeBreadcrumb
}

const casinoPermitHolderSearchBreadcrumb: BreadcrumbProps = {
  ...permitHolderSearchBreadcrumb,
  path: ROUTES.permitHolderSearchCasino
}

const rafflePermitHolderSearchBreadcrumb: BreadcrumbProps = {
  ...permitHolderSearchBreadcrumb,
  path: ROUTES.permitHolderSearchRaffle
}

const raffleBreadcrumb: BreadcrumbProps = {
  path: ROUTES.raffleReport,
  text: 'Raffle Financial Report',
  parent: rafflePermitHolderSearchBreadcrumb
}

const casinoBreadcrumb: BreadcrumbProps = {
  path: ROUTES.casinoReport,
  text: 'Casino Night Tax Report',
  parent: casinoPermitHolderSearchBreadcrumb
}

const annualBeanoBreadcrumb: BreadcrumbProps = {
  path: ROUTES.licenseeSearch,
  text: 'Annual Beano Financial Report',
  parent: licenseeSearchBreadcrumb
}

const weeklyBeanoBreadcrumb: BreadcrumbProps = {
  path: ROUTES.licenseeSearch,
  text: 'Weekly Beano Financial Report',
  parent: licenseeSearchBreadcrumb
}

export const Breadcrumbs = {
  annualBeano: annualBeanoBreadcrumb,
  home: homeBreadcrumb,
  licenseeSearch: licenseeSearchBreadcrumb,
  permitHolderSearch: permitHolderSearchBreadcrumb,
  casinoPermitHolderSearch: casinoPermitHolderSearchBreadcrumb,
  rafflePermitHolderSearch: rafflePermitHolderSearchBreadcrumb,
  casinoReport: casinoBreadcrumb,
  raffleReport: raffleBreadcrumb,
  weeklyBeano: weeklyBeanoBreadcrumb
}
