import { AppConfig } from 'src/config'
import { ROUTES } from 'src/helpers/constants'

export const ROOT_CLASS = 'main-nav'

export type NavLinkProps = {
  to: string
  displayText: string
}

const allNavLinks: NavLinkProps[] = [
  {
    to: ROUTES.licenseeSearch,
    displayText: 'Submit a Weekly Beano Tax Report'
  },
  {
    to: ROUTES.licenseeSearch,
    displayText: 'Submit an Annual Beano Tax Report'
  },
  {
    to: ROUTES.permitHolderSearchRaffle,
    displayText: 'Submit a Raffle Tax Report'
  },
  {
    to: ROUTES.permitHolderSearchCasino,
    displayText: 'Submit a Casino/Monte Carlo Night Report'
  }
]

export const navLinks: NavLinkProps[] = AppConfig.hidePermitHolder
  ? allNavLinks.filter(
      (navLink) => !navLink.to.includes(ROUTES.permitHolderSearchBase)
    )
  : allNavLinks
