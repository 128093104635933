export enum PermitHolderForm {
  raffle = 'raffle',
  casino = 'casino'
}

export enum PathParam {
  permitHolderFormType = 'type'
}

export const ROUTES = {
  home: '/',
  annualBeano: '/annual-beano-financial-report',
  casinoReport: '/casino-report',
  licenseeSearch: '/licensee-search',
  permitHolderSearchBase: '/permit-holder-search',
  permitHolderSearchRaffle: `/permit-holder-search/${PermitHolderForm.raffle}`,
  permitHolderSearchCasino: `/permit-holder-search/${PermitHolderForm.casino}`,
  raffleReport: '/raffle-report',
  weeklyBeano: '/weekly-beano-financial-report'
}
