import * as React from 'react'
import { getModifierClass, joinClasses } from 'src/helpers/components'
import { Breadcrumb } from '../breadcrumb/breadcrumb-ui'
import { BreadcrumbProps } from '../breadcrumb/types'
import './styles.scss'

export type Props = {
  className?: string
  headerText: string
  breadcrumb?: BreadcrumbProps
}

const ROOT_CLASS = 'page'

export const Page: React.FC<Props> = (props) => (
  <section
    className={joinClasses([
      ROOT_CLASS,
      props.className,
      getModifierClass(
        ROOT_CLASS,
        'with-breadcrumb',
        props.breadcrumb !== undefined
      )
    ])}
  >
    {props.breadcrumb && <Breadcrumb {...props.breadcrumb} />}
    <h1>{props.headerText}</h1>
    {props.children}
  </section>
)
