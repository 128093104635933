import * as React from 'react'
import {
  RaffleFormPrintProps,
  RaffleFormPrint
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { PrintLink } from 'src/components/print-link/print-link-ui'
import './styles.scss'

export type RafflePrintLinkProps = RaffleFormPrintProps

export const RafflePrintLink = (props: RafflePrintLinkProps) => (
  <PrintLink
    PrintElement={
      <RaffleFormPrint
        formData={props.formData}
        organizationDetails={props.organizationDetails}
      />
    }
    title={`Raffle Form | ${props.organizationDetails.organizationName} - ${props.formData.occasionDate}`}
  />
)
