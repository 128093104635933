import * as React from 'react'
import {
  AnnualBeanoFormPrintProps,
  AnnualBeanoPrint
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { PrintLink } from 'src/components/print-link/print-link-ui'
import './styles.scss'

export type AnnualBeanoPrintLinkProps = {
  year: string
} & AnnualBeanoFormPrintProps

export const AnnualBeanoPrintLink = (props: AnnualBeanoPrintLinkProps) => (
  <PrintLink
    PrintElement={
      <AnnualBeanoPrint
        formData={props.formData}
        organizationDetails={props.organizationDetails}
      />
    }
    title={`Annual Beano Form | ${props.organizationDetails.organizationName} - ${props.year}`}
  />
)
