import * as React from 'react'
import { Breadcrumbs } from 'src/components/breadcrumb/constants'
import { ErrorMessage } from 'src/components/error-message/error-message-ui'
import { LoadingIndicator } from 'src/components/loading-indicator/loading-indicator-ui'
import { NoResultsTable } from 'src/components/no-results-table/no-results-table-ui'
import { Page } from 'src/components/page/page-ui'
import { PermitHolderSearchBox } from 'src/components/permit-holder-search-box/permit-holder-search-box-ui'
import { getBlockClass } from 'src/helpers/components'
import { PermitHolderForm } from 'src/helpers/constants'
import './styles.scss'

export type PermitHolderSearchPageProps = {
  onSearch: (searchTerm?: string) => Promise<void>
  formType: PermitHolderForm
  defaultSearchTerm?: string
  loading: boolean
  noResults: boolean
  error?: string
}

const ROOT_CLASS = 'permit-holder-search-page'

const PermitHolderSearchPageContents = (props: PermitHolderSearchPageProps) => {
  if (props.loading) {
    return (
      <div className={getBlockClass(ROOT_CLASS, 'loading')}>
        <LoadingIndicator />
      </div>
    )
  } else if (props.noResults) {
    return <NoResultsTable />
  } else if (props.error) {
    return (
      <div className={getBlockClass(ROOT_CLASS, 'error')}>
        <ErrorMessage message={props.error} />
      </div>
    )
  }
  return null
}

export const PermitHolderSearchPage = (props: PermitHolderSearchPageProps) => {
  return (
    <Page
      headerText={Breadcrumbs.permitHolderSearch.text}
      className={ROOT_CLASS}
      breadcrumb={Breadcrumbs.permitHolderSearch}
    >
      <section className={getBlockClass(ROOT_CLASS, 'body')}>
        <PermitHolderSearchBox
          onSearch={props.onSearch}
          defaultSearchTerm={props.defaultSearchTerm}
        />
        <div className={getBlockClass(ROOT_CLASS, 'contents')}>
          {<PermitHolderSearchPageContents {...props} />}
        </div>
      </section>
    </Page>
  )
}
