export type Optional<T> = T | undefined

export type ValueOf<T> = T[keyof T]

export const getObjectKeys = <T>(obj: T) => Object.keys(obj) as Array<keyof T>

export const areEqualObjects = <T>(obj1: T, obj2: T): boolean => {
  const areEqual = getObjectKeys(obj1).every((key) => {
    const value1 = obj1[key]
    const value2 = obj2[key]
    if (typeof value1 === 'object' && typeof value2 === 'object') {
      return areEqualObjects(value1, value2)
    }
    return value1 === value2
  })
  return areEqual
}

export const getNestedArraysWithLength = <T>(
  arr: T[],
  subarrayLength: number
): T[][] => {
  const result: T[][] = []
  for (let i = 0; i < arr.length; i += subarrayLength) {
    result.push(arr.slice(i, i + subarrayLength))
  }
  return result
}
