import * as React from 'react'
import { AnnualBeanoSubmissionBody } from '@interaction-gaming/mslc-charitable-gaming-components'
import { getSubmitAnnualBeanoUrl } from 'src/api/charitable-gaming/constants'
import { useAppSelector } from 'src/hooks/custom-redux-hooks'
import { useAssertActiveForm } from 'src/hooks/use-assert-active-form'
import { useSubmitBeano } from 'src/hooks/use-submit-beano'
import { BeanoSelectors } from 'src/reducers/beano-form/beano-form-selectors'
import { AnnualBeanoPage } from './annual-beano-page-ui'

export const AnnualBeanoPageContainer = () => {
  const {
    loading,
    error,
    submittedData,
    isSubmitted,
    completeSubmissionFailure,
    handleSubmit
  } = useSubmitBeano<AnnualBeanoSubmissionBody>(getSubmitAnnualBeanoUrl())
  const activeForm = useAppSelector(BeanoSelectors.activeForm)
  useAssertActiveForm(activeForm)

  if (!activeForm) {
    return null
  }

  return (
    <AnnualBeanoPage
      loading={loading}
      form={activeForm}
      isSubmitted={isSubmitted}
      error={error}
      completeSubmissionFailure={completeSubmissionFailure}
      submittedData={submittedData}
      onSubmit={handleSubmit}
    />
  )
}
