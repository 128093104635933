import * as React from 'react'
import { getBlockClass } from 'src/helpers/components'
import { ROOT_CLASS, navLinks, NavLinkProps } from './constants'
import { MainNavLink } from './main-nav-link'
import './styles.scss'

export const MainNav = () => {
  return (
    <nav className={ROOT_CLASS}>
      <ul className={getBlockClass(ROOT_CLASS, 'list')}>
        {navLinks.map((linkProps: NavLinkProps) => (
          <MainNavLink key={linkProps.displayText} {...linkProps} />
        ))}
      </ul>
    </nav>
  )
}
