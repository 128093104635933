import * as React from 'react'
import { Link } from 'react-router-dom'
import { getBlockClass } from 'src/helpers/components'
import { CaretDirection, IconCaret } from '../icon-caret/icon-caret'
import { NavLinkProps, ROOT_CLASS } from './constants'
import './styles.scss'

export const MainNavLink = (props: NavLinkProps) => (
  <li className={getBlockClass(ROOT_CLASS, 'link-wrapper')}>
    <Link to={props.to} className={getBlockClass(ROOT_CLASS, 'link')}>
      <h3>{props.displayText}</h3>
      <IconCaret direction={CaretDirection.right} />
    </Link>
  </li>
)
