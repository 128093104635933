import * as React from 'react'
import {
  CasinoForm,
  CasinoFormSubmissionBody
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { PermitHolder } from 'src/api/charitable-gaming/api-response-types'
import { getOrgDetailsFromPermitHolder } from 'src/api/charitable-gaming/helpers'
import { Page } from 'src/components/page/page-ui'
import { Breadcrumbs } from 'src/components/breadcrumb/constants'
import { getBlockClass } from 'src/helpers/components'
import { LoadingIndicator } from 'src/components/loading-indicator/loading-indicator-ui'
import { CasinoSubmissionFailure } from './casino-submission-failure-ui'
import { CasinoReportSubmitted } from './report-submitted-ui'

export type CasinoPageProps = {
  completeSubmissionFailure: boolean
  error?: boolean
  loading: boolean
  isSubmitted: boolean
  submittedData?: CasinoFormSubmissionBody
  permitHolder: PermitHolder
  onSubmit: (data: CasinoFormSubmissionBody) => Promise<void>
}

const ROOT_CLASS = 'casino-page'

export const CasinoPage = (props: CasinoPageProps) => {
  const orgDetails = React.useMemo(
    () => getOrgDetailsFromPermitHolder(props.permitHolder),
    [props.permitHolder]
  )
  const getPostSubmissionContents = () => {
    if (props.submittedData) {
      if (props.isSubmitted) {
        return (
          <CasinoReportSubmitted
            formData={props.submittedData}
            organizationDetails={orgDetails}
          />
        )
      } else if (props.completeSubmissionFailure) {
        return (
          <CasinoSubmissionFailure
            organizationDetails={orgDetails}
            formData={props.submittedData}
          />
        )
      }
    }
  }

  const postSubmissionContents = getPostSubmissionContents()
  return (
    <Page
      headerText={Breadcrumbs.casinoReport.text}
      breadcrumb={Breadcrumbs.casinoReport}
      className={ROOT_CLASS}
    >
      <div className={getBlockClass(ROOT_CLASS, 'contents')}>
        {postSubmissionContents ?? (
          <CasinoForm
            organizationDetails={orgDetails}
            onSubmit={props.onSubmit}
            error={props.error}
            submitDisabled={props.loading}
          />
        )}
        {props.loading && <LoadingIndicator />}
      </div>
    </Page>
  )
}
