import { OrganizationDetailsProps } from '@interaction-gaming/mslc-charitable-gaming-components/dist/components/organization-details/organization-details-ui'
import dateUtil from 'src/services/dayjs'
import { DayFormat } from 'src/services/dayjs/constants'
import { PermitHolder, UnsubmittedForm } from './api-response-types'

export const getOrgDetailsFromForm = (
  form: UnsubmittedForm
): OrganizationDetailsProps => ({
  organizationName: form.orgName,
  idNumber: form.licenseNumber,
  address: form.occasionAddress.streetAddress,
  city: form.occasionAddress.city,
  state: 'MA',
  zipCode: form.occasionAddress.zip
})

export const getOrgDetailsFromPermitHolder = (
  permitHolder: PermitHolder
): OrganizationDetailsProps => ({
  organizationName: permitHolder.orgName,
  idNumber: permitHolder.raffleId,
  address: permitHolder.address.streetAddress,
  city: permitHolder.address.city,
  state: 'MA',
  zipCode: permitHolder.address.zip
})

export const getFormattedDateFromForm = (form: UnsubmittedForm) =>
  dateUtil.tz(form.occasionDate).format(DayFormat.monthDayYearDoubleDigit)

export const getYearFromForm = (form: UnsubmittedForm) =>
  dateUtil.tz(form.occasionDate).year() - 1 // the form for the previous year becomes due on the first of the year.
