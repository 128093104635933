export enum BeanoFormType {
  weekly = 'WEEKLY_BEANO',
  annual = 'ANNUAL_BEANO'
}

export type UnsubmittedForm = {
  licenseNumber: string
  occasionDate: string
  occasionAddress: {
    zip: string
    city: string
    streetAddress: string
    // address is deprecated, but at the time of development, some test data still uses this key
    address?: string
  }
  occasionTime: string
  formType: BeanoFormType
  occasionDay: string
  orgName: string
}

export type UnsubmittedFormResponse = {
  forms: UnsubmittedForm[]
}

export type PermitHolder = {
  raffleId: string
  cityCode: string
  orgType: string
  orgName: string
  address: {
    zip: string
    city: string
    streetAddress: string
  }
  phone: string
  dateOfFirstPermit: string
  effectiveDate: string
  expirationDate: string
  email: string
  orgStatus: string
  status: string
}

export type PermitHolderResponse = {
  permit: PermitHolder
}
