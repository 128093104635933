import * as React from 'react'
import { ExternalLink } from '@interaction-gaming/mslc-charitable-gaming-components'
import { TableBlank } from '../table-blank/table-blank-ui'
import './styles.scss'

const ROOT_CLASS = 'no-results-table'

export const NoResultsTable = () => (
  <TableBlank transparent>
    <p className={ROOT_CLASS}>
      No results. Contact the Charitable Gaming division at{' '}
      <ExternalLink href="tel:781849-5527">(781) 849-5527</ExternalLink> if you
      are having trouble.
    </p>
  </TableBlank>
)
