import * as React from 'react'
import { SubmissionFailure } from 'src/components/submission-failure/submission-failure-ui'
import {
  AnnualBeanoPrintLink,
  AnnualBeanoPrintLinkProps
} from './annual-beano-print-link-ui'

export type AnnualBeanoSubmissionFailureProps = AnnualBeanoPrintLinkProps

export const AnnualBeanoSubmissionFailure = (
  props: AnnualBeanoSubmissionFailureProps
) => {
  return (
    <SubmissionFailure
      printLink={
        <AnnualBeanoPrintLink
          formData={props.formData}
          organizationDetails={props.organizationDetails}
          year={props.year}
        />
      }
    />
  )
}
