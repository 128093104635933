import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  CharitableGamingWhiteLogo,
  Props as LogoProps
} from 'src/assets/charitable-gaming-white-logo'
import { getBlockClass } from 'src/helpers/components'
import { ROUTES } from 'src/helpers/constants'
import './styles.scss'

const ROOT_CLASS = 'menu-bar'

const logoProps: LogoProps = {
  widthPx: 170,
  heightPx: 50
}

export const MenuBar: React.FC = () => (
  <header className={ROOT_CLASS}>
    <div className={getBlockClass(ROOT_CLASS, 'container')}>
      <Link to={ROUTES.home}>
        <CharitableGamingWhiteLogo {...logoProps} />
      </Link>
    </div>
  </header>
)
