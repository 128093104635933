import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PermitHolder } from 'src/api/charitable-gaming/api-response-types'
import { PermitHolderForm } from 'src/helpers/constants'

export type PermitHolderState = {
  formType?: PermitHolderForm
  permitHolder?: PermitHolder
}

type SetPermitHolderPayload = Required<PermitHolderState>

export type PermitHolderPayload = SetPermitHolderPayload

const initialState: PermitHolderState = {}

export const permitHolderSlice = createSlice({
  name: 'permitHolder',
  initialState,
  reducers: {
    setActivePermitHolder: (
      state: PermitHolderState,
      action: PayloadAction<PermitHolderPayload>
    ) => {
      state.formType = action.payload.formType
      state.permitHolder = action.payload.permitHolder
    },
    clearActivePermitHolder: (state: PermitHolderState) => {
      state.formType = undefined
      state.permitHolder = undefined
    }
  }
})

export const PermitHolderActions = permitHolderSlice.actions
