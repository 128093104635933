import * as React from 'react'
import {
  UnsubmittedForm,
  BeanoFormType
} from 'src/api/charitable-gaming/api-response-types'
import { IconAlert } from 'src/assets/icon-alert/icon-alert'
import { getBlockClass } from 'src/helpers/components'
import dateUtil from 'src/services/dayjs'
import { DayFormat } from 'src/services/dayjs/constants'
import { ContinueToReport } from '../button-continue-to-report/button-continue-to-report'
import { Table } from '../table/table-ui'
import './styles.scss'

export type Props = {
  forms: UnsubmittedForm[]
  onContinueClick: (form: UnsubmittedForm) => void
}

type UnsubmittedFormRow = {
  organization: string
  address: string
  date: JSX.Element
}

type LicenseeTableHeader = {
  text: string
  dataKey: keyof UnsubmittedFormRow
}

const tableHeaders: LicenseeTableHeader[] = [
  { text: 'Organization', dataKey: 'organization' },
  { text: 'Address', dataKey: 'address' },
  { text: 'Date', dataKey: 'date' }
]

export const ROOT_CLASS = 'licensee-search-results'
const TABLE_CLASS = getBlockClass(ROOT_CLASS, 'table')

const mapFormToRow = (
  onContinueClick: (form: UnsubmittedForm) => void,
  form: UnsubmittedForm,
  useAlert = true
): UnsubmittedFormRow => {
  const date = dateUtil.tz(form.occasionDate)
  const tenDaysAgo = dateUtil().subtract(10, 'days')
  return {
    organization: form.orgName,
    address: `${
      // address is deprecated, but at the time of development is still being used for some test data
      form.occasionAddress.streetAddress ?? form.occasionAddress.address
    }, ${form.occasionAddress.city}, MA ${form.occasionAddress.zip}`,
    date: (
      <div className={getBlockClass(ROOT_CLASS, 'cta-container')}>
        <div className={getBlockClass(ROOT_CLASS, 'date-container')}>
          <p>{date.format(DayFormat.monthDayYearDoubleDigit)}</p>
          {useAlert && tenDaysAgo.isAfter(date) && <IconAlert tip="Overdue" />}
        </div>
        <ContinueToReport
          reportType={
            form.formType === BeanoFormType.weekly ? 'Weekly' : 'Annual'
          }
          onClick={() => onContinueClick(form)}
        />
      </div>
    )
  }
}

export const LicenseeSearchResults = (props: Props) => {
  const weeklyBeanoForms: UnsubmittedForm[] = []
  const annualBeanoForms: UnsubmittedForm[] = []
  props.forms.forEach((result) =>
    result.formType === BeanoFormType.weekly
      ? weeklyBeanoForms.push(result)
      : annualBeanoForms.push(result)
  )

  const curriedMapFormToRow = (form: UnsubmittedForm) =>
    mapFormToRow(props.onContinueClick, form)

  return (
    <section className={ROOT_CLASS}>
      {weeklyBeanoForms.length > 0 && (
        <>
          <h2>Weekly Reports</h2>
          <Table
            headers={tableHeaders}
            rows={weeklyBeanoForms.map(curriedMapFormToRow)}
            className={TABLE_CLASS}
          />
        </>
      )}
      {annualBeanoForms.length > 0 && (
        <>
          <h2>Annual Reports</h2>
          <Table
            headers={tableHeaders}
            rows={annualBeanoForms.map(curriedMapFormToRow)}
            className={TABLE_CLASS}
          />
        </>
      )}
    </section>
  )
}
