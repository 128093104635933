import * as React from 'react'
import {
  AnnualBeanoForm,
  AnnualBeanoSubmissionBody
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { UnsubmittedForm } from 'src/api/charitable-gaming/api-response-types'
import { Breadcrumbs } from 'src/components/breadcrumb/constants'
import { LoadingIndicator } from 'src/components/loading-indicator/loading-indicator-ui'
import { Page } from 'src/components/page/page-ui'
import { getBlockClass } from 'src/helpers/components'
import {
  getYearFromForm,
  getOrgDetailsFromForm
} from 'src/api/charitable-gaming/helpers'
import { AnnualBeanoReportSubmitted } from './submission/report-submitted-ui'
import { AnnualBeanoSubmissionFailure } from './submission/submission-failure-ui'
import './styles.scss'

export type AnnualBeanoPageProps = {
  loading: boolean
  error?: string
  completeSubmissionFailure: boolean
  isSubmitted: boolean
  form: UnsubmittedForm
  submittedData?: AnnualBeanoSubmissionBody
  onSubmit: (data: AnnualBeanoSubmissionBody) => Promise<void>
}

const ROOT_CLASS = 'annual-beano-page'

export const AnnualBeanoPage = (props: AnnualBeanoPageProps) => {
  const { orgDetails, year } = React.useMemo(
    () => ({
      orgDetails: getOrgDetailsFromForm(props.form),
      year: String(getYearFromForm(props.form))
    }),
    [props.form]
  )
  const getSubmissionContents = () => {
    if (props.completeSubmissionFailure && props.submittedData) {
      return (
        <AnnualBeanoSubmissionFailure
          formData={props.submittedData}
          organizationDetails={orgDetails}
          year={year}
        />
      )
    } else if (props.isSubmitted && props.submittedData) {
      return (
        <AnnualBeanoReportSubmitted
          formData={props.submittedData}
          organizationDetails={orgDetails}
          year={year}
        />
      )
    }
  }

  const postSubmissionContents = getSubmissionContents()
  return (
    <Page
      className={ROOT_CLASS}
      headerText={Breadcrumbs.annualBeano.text}
      breadcrumb={Breadcrumbs.annualBeano}
    >
      <div className={getBlockClass(ROOT_CLASS, 'form-container')}>
        {postSubmissionContents ?? (
          <AnnualBeanoForm
            organizationDetails={orgDetails}
            year={year}
            onSubmit={props.onSubmit}
            error={Boolean(props.error)}
            submitDisabled={props.loading}
          />
        )}
        {props.loading && <LoadingIndicator />}
      </div>
    </Page>
  )
}
