import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PathParam, PermitHolderForm } from 'src/helpers/constants'
import { ROUTES } from 'src/helpers/constants'
import { PermitHolderSelectors } from 'src/reducers/permit-holder/permit-holder-selectors'
import { useAppSelector } from './custom-redux-hooks'

const isValidPermitHolderType = (
  varToCheck: any
): varToCheck is PermitHolderForm =>
  Object.values(PermitHolderForm).includes(varToCheck)

export const useAssertPermitHolderFormPath = () => {
  const navigate = useNavigate()
  const { type: pathFormType } = useParams<PathParam.permitHolderFormType>()
  React.useEffect(() => {
    if (!pathFormType || !isValidPermitHolderType(pathFormType)) {
      navigate(ROUTES.home)
    }
  }, [pathFormType, navigate])
  return {
    pathFormType: isValidPermitHolderType(pathFormType)
      ? pathFormType
      : undefined
  }
}

export const useAssertPermitHolderFormState = () => {
  const navigate = useNavigate()
  const { formType, permitHolder } = useAppSelector(PermitHolderSelectors.state)
  React.useEffect(() => {
    if (!formType || !permitHolder) {
      navigate(ROUTES.home)
    }
  }, [formType, permitHolder, navigate])
  return {
    formType,
    permitHolder
  }
}
