import * as React from 'react'
import { Caret, CaretColor } from 'src/assets/caret'
import './styles.scss'

export enum CaretDirection {
  down = 0,
  right = 90,
  up = 180,
  left = 270
}

export type Props = {
  height?: number
  width?: number
  direction?: CaretDirection
  color?: CaretColor
}

const ROOT_CLASS = 'icon-caret'

export const IconCaret = (props: Props) => {
  const colorClassName = `${ROOT_CLASS}--${
    props.color ?? CaretColor.primaryTeal
  }`
  return (
    <div
      className={`${ROOT_CLASS} ${colorClassName}`}
      style={{
        transform: `rotate(-${props.direction ?? CaretDirection.down}deg)`
      }}
    >
      <Caret height={props.height} width={props.width} />
    </div>
  )
}
