import * as React from 'react'
import { MainNav } from 'src/components/main-nav/main-nav-ui'
import { Page } from 'src/components/page/page-ui'
import { HOME_PAGE_TITLE } from './constants'
import './styles.scss'

const ROOT_CLASS = 'home-page'

export const HomePage = () => (
  <Page headerText={HOME_PAGE_TITLE} className={ROOT_CLASS}>
    <MainNav />
  </Page>
)
