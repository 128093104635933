import * as React from 'react'
import { joinClasses } from 'src/helpers/components'
import './styles.scss'

type Props = {
  className?: string
}

const ROOT_CLASS = 'validation-error-message'

export const ValidationErrorMessage: React.FC<Props> = (props) => (
  <p className={joinClasses([ROOT_CLASS, props.className])}>{props.children}</p>
)
