import * as React from 'react'
import { PaymentInstruction } from 'src/components/payment-instruction/payment-instruction-ui'
import { ReportSubmittedContainer } from 'src/components/report-submitted/report-submitted-container'
import { RafflePrintLink, RafflePrintLinkProps } from './raffle-print-link-ui'
import './styles.scss'

export type RaffleReportSubmittedProps = RafflePrintLinkProps

const ROOT_CLASS = 'raffle-form-submitted'

export const RaffleReportSubmitted = (props: RaffleReportSubmittedProps) => {
  return (
    <ReportSubmittedContainer
      type="permitHolderRaffle"
      buttonText="Back to Permit Holder Search"
    >
      <div className={ROOT_CLASS}>
        Your report has been submitted. <PaymentInstruction />
        <br />
        Click{' '}
        <RafflePrintLink
          formData={props.formData}
          organizationDetails={props.organizationDetails}
        />{' '}
        to print a copy of this form for your files.
      </div>
    </ReportSubmittedContainer>
  )
}
