import * as React from 'react'
import { useAssertPermitHolderFormState } from 'src/hooks/use-assert-permit-holder-form'
import { useSubmitCasinoForm } from 'src/hooks/use-submit-permit-holder'
import { CasinoPage } from './casino-page-ui'

export const CasinoPageContainer = () => {
  const fetchData = useSubmitCasinoForm()
  const { formType, permitHolder } = useAssertPermitHolderFormState()
  if (!permitHolder || !formType) {
    return null
  }
  return (
    <CasinoPage
      onSubmit={fetchData.handleSubmit}
      loading={fetchData.loading}
      error={Boolean(fetchData.error)}
      completeSubmissionFailure={fetchData.completeSubmissionFailure}
      isSubmitted={fetchData.isSubmitted}
      permitHolder={permitHolder}
      submittedData={fetchData.submittedData}
    />
  )
}
