import { UseFormReturn } from '@interaction-gaming/mslc-charitable-gaming-components'
import {
  AnythingToReportField,
  AnythingToReportFormData,
  AnythingToReportValue
} from './constants'

type GetValuesType = UseFormReturn<AnythingToReportFormData>['getValues']

const getLabelFromFieldName = (fieldName: AnythingToReportField) => {
  switch (fieldName) {
    case AnythingToReportField.email:
      return 'Email'
    case AnythingToReportField.signature:
      return 'Signature'
    case AnythingToReportField.noGameReason:
      return 'Reason for no game'
    default:
      return 'This field'
  }
}

export const requiredIfNotYes = (
  getValues: GetValuesType,
  fieldName: AnythingToReportField,
  value?: string
) => {
  const checkedValue = getValues(AnythingToReportField.yesOrNo)
  if (checkedValue === AnythingToReportValue.no) {
    return value ? true : `${getLabelFromFieldName(fieldName)} is required`
  }
  return true
}

export const getRequiredIfYesValidation = (
  getValues: GetValuesType,
  fieldName: AnythingToReportField
) => ({
  validate: {
    requiredIfNotYes: (value?: string) =>
      requiredIfNotYes(getValues, fieldName, value)
  },
  required: false
})
