import * as React from 'react'
import { CasinoFormPrintProps } from '@interaction-gaming/mslc-charitable-gaming-components'
import { SubmissionFailure } from 'src/components/submission-failure/submission-failure-ui'
import { CasinoPrintLink } from './casino-print-link-ui'

export type CasinoSubmissionFailureProps = CasinoFormPrintProps

export const CasinoSubmissionFailure = (
  props: CasinoSubmissionFailureProps
) => {
  return (
    <SubmissionFailure
      printLink={
        <CasinoPrintLink
          formData={props.formData}
          organizationDetails={props.organizationDetails}
        />
      }
    />
  )
}
