import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import { PathParam, ROUTES } from 'src/helpers/constants'
import { HomePage } from 'src/pages/home/home-page-ui'
import { MenuBar } from 'src/components/menu-bar/menu-bar-ui'
import { LicenseeSearchPageContainer } from 'src/pages/licensee-search/licensee-search-page-container'
import { WeeklyBeanoPageContainer } from 'src/pages/weekly-beano/weekly-beano-page-container'
import { AnnualBeanoPageContainer } from 'src/pages/annual-beano/annual-beano-page-container'
import { PermitHolderSearchPageContainer } from 'src/pages/permit-holder-search/permit-holder-search-page-container'
import { CasinoPageContainer } from 'src/pages/casino/casino-page-container'
import { RafflePageContainer } from 'src/pages/raffle/raffle-page-container'
import { Footer } from '../footer/footer-ui'
import { AppConfig } from 'src/config'
import './styles.scss'

const ROOT_CLASS = 'app'

export const App = () => {
  return (
    <>
      <main className={ROOT_CLASS}>
        <MenuBar />
        <Routes>
          <Route path={ROUTES.home} element={<HomePage />} />
          <Route
            path={ROUTES.licenseeSearch}
            element={<LicenseeSearchPageContainer />}
          />
          {!AppConfig.hidePermitHolder && (
            <>
              <Route
                path={`${ROUTES.permitHolderSearchBase}/:${PathParam.permitHolderFormType}`}
                element={<PermitHolderSearchPageContainer />}
              />
              <Route
                path={ROUTES.permitHolderSearchBase}
                element={<PermitHolderSearchPageContainer />}
              />
              <Route
                path={ROUTES.raffleReport}
                element={<RafflePageContainer />}
              />
              <Route
                path={ROUTES.casinoReport}
                element={<CasinoPageContainer />}
              />
            </>
          )}
          <Route
            path={ROUTES.weeklyBeano}
            element={<WeeklyBeanoPageContainer />}
          />
          <Route
            path={ROUTES.annualBeano}
            element={<AnnualBeanoPageContainer />}
          />
        </Routes>
      </main>
      <Footer />
    </>
  )
}
