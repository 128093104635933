import * as React from 'react'
import { getBlockClass } from 'src/helpers/components'
import { usePrint } from 'src/hooks/use-print'
import { ButtonInlineText } from '../button-inline-text/button-inline-text-ui'
import './styles.scss'

export type PrintLinkProps = {
  title: string
  PrintElement: React.ReactElement
  linkText?: string
}

const ROOT_CLASS = 'print-link'

export const PrintLink = (props: PrintLinkProps) => {
  const printRef = React.createRef<HTMLDivElement>()
  const customHandlePrint = usePrint(
    {
      documentTitle: props.title
    },
    printRef
  )
  return (
    <>
      <ButtonInlineText onClick={customHandlePrint}>
        {props.linkText ?? 'here'}
      </ButtonInlineText>
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        <div ref={printRef}>{props.PrintElement}</div>
      </div>
    </>
  )
}
