import * as React from 'react'
import { RaffleFormPrintProps } from '@interaction-gaming/mslc-charitable-gaming-components'
import { SubmissionFailure } from 'src/components/submission-failure/submission-failure-ui'
import { RafflePrintLink } from './raffle-print-link-ui'

export type RaffleSubmissionFailureProps = RaffleFormPrintProps

export const RaffleSubmissionFailure = (
  props: RaffleSubmissionFailureProps
) => {
  return (
    <SubmissionFailure
      printLink={
        <RafflePrintLink
          formData={props.formData}
          organizationDetails={props.organizationDetails}
        />
      }
    />
  )
}
