import * as React from 'react'
import {
  AnnualBeanoPrintLink,
  AnnualBeanoPrintLinkProps
} from './annual-beano-print-link-ui'
import { ReportSubmittedContainer } from 'src/components/report-submitted/report-submitted-container'
import './styles.scss'

export type AnnualBeanoReportSubmittedProps = AnnualBeanoPrintLinkProps

const ROOT_CLASS = 'annual-beano-report-submitted'

export const AnnualBeanoReportSubmitted = (
  props: AnnualBeanoReportSubmittedProps
) => {
  return (
    <ReportSubmittedContainer
      type="licenseeSearch"
      buttonText="Back to Licensee Search"
    >
      <div className={ROOT_CLASS}>
        Your annual report has been submitted. Click{' '}
        <AnnualBeanoPrintLink
          formData={props.formData}
          organizationDetails={props.organizationDetails}
          year={props.year}
        />{' '}
        to print a copy of this form for your files.
      </div>
    </ReportSubmittedContainer>
  )
}
