import * as React from 'react'
import {
  AnnualBeanoSubmissionBody,
  CasinoFormSubmissionBody,
  RaffleFormSubmissionBody,
  WeeklyBeanoFormSubmission
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { useFetch } from './use-fetch'
import {} from 'src/api/charitable-gaming/constants'

const MAX_SUBMISSION_ATTEMPTS = 3

type SubmitSuccess = { id: string }

type FormSubmission =
  | AnnualBeanoSubmissionBody
  | WeeklyBeanoFormSubmission
  | RaffleFormSubmissionBody
  | CasinoFormSubmissionBody

export const useSubmitForm = <SubmitFormData extends FormSubmission>(
  url: string
) => {
  const [numAttempts, setNumAttempts] = React.useState(0)
  const [submittedData, setSubmittedData] = React.useState<SubmitFormData>()
  const { handleFetch, data, error, loading } = useFetch<SubmitSuccess>('POST')

  const handleSubmit = async (data: SubmitFormData) => {
    setNumAttempts(numAttempts + 1)
    setSubmittedData(data)
    await handleFetch(url, data)
  }

  const completeSubmissionFailure = Boolean(
    numAttempts >= MAX_SUBMISSION_ATTEMPTS && error
  )

  return {
    loading,
    completeSubmissionFailure,
    response: data,
    error,
    isSubmitted: !error && data !== undefined,
    submittedData,
    handleSubmit
  }
}
