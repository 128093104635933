import * as React from 'react'
import Lottie from 'react-lottie-player'
import lottieJson from './loading-swirl.json'
import './styles.scss'

const defaultDimensions = 64

type Props = {
  dimensions?: number
}

const ROOT_CLASS = 'loading-indicator'

export const LoadingIndicator = (props: Props) => {
  const dimensions = props.dimensions ?? defaultDimensions
  return (
    <div className={ROOT_CLASS}>
      <Lottie
        loop
        play
        animationData={lottieJson}
        style={{ width: dimensions, height: dimensions }}
      />
    </div>
  )
}
