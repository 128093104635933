export enum AnythingToReportField {
  yesOrNo = 'yesOrNo',
  noGameReason = 'noGameReason',
  signature = 'signature',
  email = 'email'
}

export enum AnythingToReportValue {
  yes = 'yes',
  no = 'no'
}

export type AnythingToReportFormData = {
  [AnythingToReportField.yesOrNo]: AnythingToReportValue
  [AnythingToReportField.noGameReason]: string
  [AnythingToReportField.email]: string
  [AnythingToReportField.signature]: string
}
