import * as React from 'react'
import { Button } from '@interaction-gaming/mslc-charitable-gaming-components'
import { getBlockClass } from 'src/helpers/components'
import './styles.scss'

export type ReportSubmittedProps = {
  buttonText: string
  onButtonClick: () => void
}

const ROOT_CLASS = 'report-submitted'

export const ReportSubmitted: React.FC<ReportSubmittedProps> = (props) => (
  <div className={ROOT_CLASS}>
    <div className={getBlockClass(ROOT_CLASS, 'contents')}>
      {props.children}
    </div>
    <Button htmlProps={{ onClick: props.onButtonClick }}>
      {props.buttonText}
    </Button>
  </div>
)
