import * as React from 'react'
import { ExternalLink } from '@interaction-gaming/mslc-charitable-gaming-components'
import './styles.scss'

const ROOT_CLASS = 'payment-link'
const PAYMENT_LINK =
  'https://www.ncourt.com/x-press/X-onlinepayments.aspx?Juris=0e425e78-d4ef-4410-a872-caeb53ab13a5'
const DEFAULT_TEXT = 'here'

export type PaymentLinkProps = {
  linkText?: string
}

export const PaymentLink = (props: PaymentLinkProps) => (
  <>
    <ExternalLink
      className={ROOT_CLASS}
      ariaLabel={'Payment Link'}
      href={PAYMENT_LINK}
      hideExternalLinkIcon
    >
      {props.linkText ?? DEFAULT_TEXT}
    </ExternalLink>
  </>
)
