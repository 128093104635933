import * as React from 'react'
import './styles.scss'

type Props = {
  tip?: string
}

const ROOT_CLASS = 'icon-alert'

export const IconAlert = (props: Props) => (
  <span className={ROOT_CLASS} title={props.tip}>
    !
  </span>
)
