import * as React from 'react'
import { call } from 'src/api'

export const useFetch = <T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET'
) => {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<T>()
  const [error, setError] = React.useState<string>()
  const handleFetch = async (url: string, body?: object) => {
    try {
      setError(undefined)
      setLoading(true)
      const response = await call(url, {
        method: method,
        body: method === 'POST' && body ? JSON.stringify(body) : undefined
      })
      if (!response.ok) {
        const { message } = await response.json()
        throw new Error(message ?? 'Something went wrong.')
      }
      const result: T = await response.json()
      setData(result)
    } catch (e: any) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    data,
    error,
    handleFetch,
    reset: () => {
      setData(undefined)
      setError(undefined)
      setLoading(false)
    }
  }
}
