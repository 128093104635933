import { Optional } from './util'

export const getBlockClass = (rootClass: string, blockClass: string) =>
  `${rootClass}__${blockClass}`

export const getModifierClass = (
  rootClass: string,
  modifierClass: string,
  predicate = true
) => (predicate ? `${rootClass}--${modifierClass}` : rootClass)

export const joinClasses = (classes: Optional<string>[]): string =>
  Array.from(new Set(classes)).filter(Boolean).join(' ')

export const getCSSWithCustomProperties = (
  customProperties: Record<string, string>,
  cssProperties?: React.CSSProperties
): React.CSSProperties => {
  const styles = cssProperties || {}
  return {
    ...styles,
    ...customProperties
  } as React.CSSProperties
}

export const ComponentHelpers = {
  getBlockClass,
  getCSSWithCustomProperties,
  getModifierClass,
  joinClasses
}
