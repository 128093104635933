import * as React from 'react'
import { joinClasses } from 'src/helpers/components'
import './styles.scss'

type MessageProps = {
  message: string
}

type TypeProps = {
  type: 'Try Again' | 'Try Again Later'
}

export type ErrorMessageProps = {
  className?: string
} & (MessageProps | TypeProps)

const ROOT_CLASS = 'error-message'

const mapTypeToMessage = (type: Pick<TypeProps, 'type'>['type']) => {
  switch (type) {
    case 'Try Again Later':
      return 'Something went wrong. Please try again later.'
    case 'Try Again':
    default:
      return 'Something went wrong. Please try again.'
  }
}

export const ErrorMessage = (props: ErrorMessageProps) => (
  <p className={joinClasses([ROOT_CLASS, props.className])}>
    {'message' in props ? props.message : mapTypeToMessage(props.type)}
  </p>
)
