import {
  CasinoFormSubmissionBody,
  RaffleFormSubmissionBody
} from '@interaction-gaming/mslc-charitable-gaming-components'
import {
  getSubmitCasinoReportUrl,
  getSubmitRaffleReportUrl
} from 'src/api/charitable-gaming/constants'
import { useSubmitForm } from './use-submit-form'

export const useSubmitCasinoForm = () =>
  useSubmitForm<CasinoFormSubmissionBody>(getSubmitCasinoReportUrl())

export const useSubmitRaffleForm = () =>
  useSubmitForm<RaffleFormSubmissionBody>(getSubmitRaffleReportUrl())
