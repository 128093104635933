import * as React from 'react'
import { joinClasses } from 'src/helpers/components'
import './styles.scss'

type Props = {
  className?: string
  htmlProps?: React.HTMLAttributes<HTMLButtonElement>
  onClick: () => void
}

export const ROOT_CLASS = 'button-inline-text'

export const ButtonInlineText: React.FC<Props> = (props) => (
  <button
    className={joinClasses([ROOT_CLASS, props.className])}
    {...props.htmlProps}
    onClick={props.onClick}
  >
    {props.children}
  </button>
)
