import * as React from 'react'
import {
  RaffleForm,
  RaffleFormSubmissionBody
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { Breadcrumbs } from 'src/components/breadcrumb/constants'
import { Page } from 'src/components/page/page-ui'
import { getBlockClass } from 'src/helpers/components'
import { LoadingIndicator } from 'src/components/loading-indicator/loading-indicator-ui'
import { PermitHolder } from 'src/api/charitable-gaming/api-response-types'
import { RaffleReportSubmitted } from './submission/report-submitted-ui'
import { getOrgDetailsFromPermitHolder } from 'src/api/charitable-gaming/helpers'
import { RaffleSubmissionFailure } from './submission/submission-failure-ui'
import './styles.scss'

export type RafflePageProps = {
  completeSubmissionFailure: boolean
  error?: boolean
  loading: boolean
  isSubmitted: boolean
  submittedData?: RaffleFormSubmissionBody
  permitHolder: PermitHolder
  onSubmit: (data: RaffleFormSubmissionBody) => Promise<void>
}

const ROOT_CLASS = 'raffle-report-page'

export const RafflePage = (props: RafflePageProps) => {
  const orgDetails = React.useMemo(
    () => getOrgDetailsFromPermitHolder(props.permitHolder),
    [props.permitHolder]
  )

  const getPostSubmissionContents = () => {
    if (props.submittedData) {
      if (props.isSubmitted) {
        return (
          <RaffleReportSubmitted
            formData={props.submittedData}
            organizationDetails={orgDetails}
          />
        )
      } else if (props.completeSubmissionFailure) {
        return (
          <RaffleSubmissionFailure
            organizationDetails={orgDetails}
            formData={props.submittedData}
          />
        )
      }
    }
  }

  const postSubmissionContents = getPostSubmissionContents()
  return (
    <Page
      headerText={Breadcrumbs.raffleReport.text}
      breadcrumb={Breadcrumbs.raffleReport}
      className={ROOT_CLASS}
    >
      <div className={getBlockClass(ROOT_CLASS, 'contents')}>
        {postSubmissionContents ?? (
          <RaffleForm
            organizationDetails={orgDetails}
            onSubmit={props.onSubmit}
            error={props.error}
            submitDisabled={props.loading}
          />
        )}
        {props.loading && <LoadingIndicator />}
      </div>
    </Page>
  )
}
