import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { UnsubmittedForm } from 'src/api/charitable-gaming/api-response-types'
import { ROUTES } from 'src/helpers/constants'

export const useAssertActiveForm = (activeForm?: UnsubmittedForm) => {
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!activeForm) {
      navigate(ROUTES.licenseeSearch)
    }
  }, [activeForm, navigate])
}
