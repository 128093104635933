import * as React from 'react'
import { getBlockClass } from 'src/helpers/components'
import dateUtil from 'src/services/dayjs'
import './styles.scss'

const ROOT_CLASS = 'footer'
const CONTACT_METHOD_CLASS = getBlockClass(ROOT_CLASS, 'contact-method')
const DIVIDER_CLASS = getBlockClass(ROOT_CLASS, 'divider')
const LEGALESE_CLASS = getBlockClass(ROOT_CLASS, 'legalese')

export const Footer = () => (
  <footer className={ROOT_CLASS}>
    <div className={getBlockClass(ROOT_CLASS, 'contents')}>
      <div className={getBlockClass(ROOT_CLASS, 'top')}>
        <b className={getBlockClass(ROOT_CLASS, 'contact-us')}>Contact Us</b>
        <span className={DIVIDER_CLASS}> </span>
        <a
          id="mlcg-phone-contact"
          className={CONTACT_METHOD_CLASS}
          href="tel:781-849-5527"
        >
          (781) 849-5527
        </a>
        <span className={DIVIDER_CLASS}> </span>
        <p className={CONTACT_METHOD_CLASS}>
          150 Mount Vernon Street Dorchester, MA 02125-3573
        </p>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'bottom')}>
        <b className={LEGALESE_CLASS}>
          The Lottery would like to remind players that it is UNLAWFUL for
          anyone under the age of 18 to play.
        </b>
        <p className={LEGALESE_CLASS}>
          © {dateUtil().year()} Massachusetts State Lottery Commission, All
          Rights Reserved
        </p>
        <p className={LEGALESE_CLASS}>
          A Commission under the Office of the Treasurer and Receiver General of
          the Commonwealth
        </p>
      </div>
    </div>
  </footer>
)
