import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from 'src/helpers/components'
import './styles.scss'

type Props = {
  transparent?: boolean
}

const ROOT_CLASS = 'table-blank'

export const TableBlank: React.FC<Props> = (props) => (
  <div
    className={joinClasses([
      ROOT_CLASS,
      getModifierClass(ROOT_CLASS, 'transparent', props.transparent === true)
    ])}
  >
    <div className={getBlockClass(ROOT_CLASS, 'header')} />
    <div className={getBlockClass(ROOT_CLASS, 'contents')}>
      {props.children}
    </div>
  </div>
)
