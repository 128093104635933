import * as React from 'react'
import { getBlockClass } from 'src/helpers/components'
import { ErrorMessage } from '../error-message/error-message-ui'
import './styles.scss'

export type SubmissionFailureProps = {
  printLink: JSX.Element
}

const ROOT_CLASS = 'submission-failure'

export const SubmissionFailure = (props: SubmissionFailureProps) => {
  return (
    <div className={ROOT_CLASS}>
      <ErrorMessage type="Try Again Later" />
      <div className={getBlockClass(ROOT_CLASS, 'text')}>
        Click {props.printLink} to print a copy of this form for your files.
      </div>
    </div>
  )
}
