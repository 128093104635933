import * as React from 'react'
import { Link } from 'react-router-dom'
import { Optional } from 'src/helpers/util'
import { BreadcrumbProps } from './types'
import './styles.scss'

const ROOT_CLASS = 'breadcrumb'

function getBreadcrumbJSX(path: string, text: string, hasChild = false) {
  if (!hasChild) {
    return <div className={`${ROOT_CLASS}-current-page`}>{text}</div>
  }
  return (
    <Link to={path} className={`${ROOT_CLASS}-link`}>
      {text}
    </Link>
  )
}

function getBreadcrumb(
  breadcrumb: Optional<BreadcrumbProps>,
  hasChild = false
): JSX.Element | null {
  if (!breadcrumb) {
    return null
  }

  const markup = getBreadcrumbJSX(breadcrumb.path, breadcrumb.text, hasChild)
  const parentBreadcrumb = getBreadcrumb(breadcrumb.parent, true)
  const arrow = parentBreadcrumb ? (
    <div className={`${ROOT_CLASS}-arrow`}>{'>'}</div>
  ) : null
  return (
    <>
      {parentBreadcrumb} {arrow} {markup}
    </>
  )
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  return <div className={ROOT_CLASS}>{getBreadcrumb(props)}</div>
}
