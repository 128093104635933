import * as React from 'react'
import { UnsubmittedFormResponse } from 'src/api/charitable-gaming/api-response-types'
import { getUnsubmittedFormsUrl } from 'src/api/charitable-gaming/constants'
import { useFetch } from './use-fetch'

export const useFetchUnsubmittedForms = () => {
  const [fetched, setFetched] = React.useState<boolean>(false)
  const { handleFetch, data, error, loading, reset } =
    useFetch<UnsubmittedFormResponse>('POST')

  const handleSearch = async (licenseNumber?: string) => {
    if (!licenseNumber) {
      reset()
      setFetched(false)
      return
    }
    setFetched(false)
    const results = await handleFetch(getUnsubmittedFormsUrl(), {
      licenseNumber
    })
    setFetched(true)
    return results
  }

  return {
    loading,
    data,
    error,
    fetched,
    handleSearch
  }
}
