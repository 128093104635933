import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UnsubmittedForm } from 'src/api/charitable-gaming/api-response-types'

export type BeanoState = {
  activeForm?: UnsubmittedForm
}

type UpdateFormPayload = Required<BeanoState>['activeForm']

export type BeanoPayload = UpdateFormPayload

const initialState: BeanoState = {}

export const beanoSlice = createSlice({
  name: 'beano',
  initialState,
  reducers: {
    setActiveForm: (state: BeanoState, action: PayloadAction<BeanoPayload>) => {
      state.activeForm = action.payload
    },
    clearActiveForm: (state: BeanoState) => {
      state.activeForm = undefined
    }
  }
})

export const BeanoActions = beanoSlice.actions
