import * as React from 'react'
import { ButtonContinue } from '../button-continue/button-continue-ui'

type Props = {
  onClick: () => unknown
  reportType: 'Weekly' | 'Annual'
}

export const ContinueToReport = (props: Props) => (
  <ButtonContinue
    onClick={props.onClick}
  >{`Continue to ${props.reportType} Report`}</ButtonContinue>
)
