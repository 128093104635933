import * as React from 'react'
import {
  WeeklyBeanoFormBodySubmission,
  WeeklyBeanoFormSubmission
} from '@interaction-gaming/mslc-charitable-gaming-components'
import {
  AnythingToReportField,
  AnythingToReportFormData,
  AnythingToReportValue
} from 'src/components/anything-to-report/constants'
import { WeeklyBeanoPage } from './weekly-beano-page-ui'
import { useAppSelector } from 'src/hooks/custom-redux-hooks'
import { BeanoSelectors } from 'src/reducers/beano-form/beano-form-selectors'
import { useAssertActiveForm } from 'src/hooks/use-assert-active-form'
import { getNoGameWeeklyBeanoReport } from './weekly-beano-helpers'
import { useSubmitForm } from 'src/hooks/use-submit-form'
import { getSubmitWeeklyBeanoUrl } from 'src/api/charitable-gaming/constants'

export const WeeklyBeanoPageContainer = () => {
  const [showFullForm, setShowFullForm] = React.useState(false)
  const {
    handleSubmit,
    loading,
    error,
    response,
    completeSubmissionFailure,
    submittedData
  } = useSubmitForm<WeeklyBeanoFormSubmission>(getSubmitWeeklyBeanoUrl())
  const activeForm = useAppSelector(BeanoSelectors.activeForm)

  useAssertActiveForm(activeForm)

  if (!activeForm) {
    return null
  }

  const onSubmitFullForm = async (data: WeeklyBeanoFormBodySubmission) => {
    await handleSubmit(data)
  }

  const onSubmitReportForm = async (data: AnythingToReportFormData) => {
    if (data[AnythingToReportField.yesOrNo] === AnythingToReportValue.yes) {
      setShowFullForm(true)
    } else {
      const noGameReport = getNoGameWeeklyBeanoReport(
        data,
        activeForm.occasionDate,
        activeForm.occasionDay,
        activeForm.licenseNumber
      )
      await handleSubmit(noGameReport)
    }
  }

  return (
    <WeeklyBeanoPage
      loading={loading}
      submitted={!error && response !== undefined}
      submittedData={submittedData}
      error={error}
      completeSubmissionFailure={completeSubmissionFailure}
      form={activeForm}
      showFullForm={showFullForm}
      onSubmitReportForm={onSubmitReportForm}
      onSubmitFullForm={onSubmitFullForm}
    />
  )
}
