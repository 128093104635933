import * as React from 'react'
import {
  WeeklyBeanoFormPrint,
  WeeklyBeanoFormPrintProps
} from '@interaction-gaming/mslc-charitable-gaming-components'
import { ButtonInlineText } from 'src/components/button-inline-text/button-inline-text-ui'
import { getBlockClass } from 'src/helpers/components'
import { usePrint } from 'src/hooks/use-print'
import './styles.scss'

export type Props = WeeklyBeanoFormPrintProps

export const ROOT_CLASS = 'weekly-beano-print-link'

export const WeeklyBeanoPrintLink = (props: Props) => {
  const printRef = React.createRef<HTMLSelectElement>()
  const customHandlePrint = usePrint(
    {
      documentTitle: `Weekly Beano Form | ${props.organizationDetails.organizationName} - ${props.dateString}`
    },
    printRef
  )
  return (
    <>
      <ButtonInlineText onClick={customHandlePrint}>here</ButtonInlineText>
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        <WeeklyBeanoFormPrint
          organizationDetails={props.organizationDetails}
          dateString={props.dateString}
          formData={props.formData}
          ref={printRef}
          occasionTime={props.occasionTime}
        />
      </div>
    </>
  )
}
