import * as React from 'react'
import {
  getBlockClass,
  getCSSWithCustomProperties
} from 'src/helpers/components'
import { getObjectKeys } from 'src/helpers/util'
import './styles.scss'

type DataKey = string
type CellData = string | number | React.ReactNode

export type SimpleHeaderCell = {
  text: string
  dataKey: DataKey
}

export type SimpleRowCell = Record<DataKey, CellData>

export type Props = {
  headers: SimpleHeaderCell[]
  rows: SimpleRowCell[]
  className?: string
}

const ROOT_CLASS = 'simple-table'
const ROW_CLASS = getBlockClass(ROOT_CLASS, 'row')
const CELL_CLASS = getBlockClass(ROOT_CLASS, 'cell')

export const Table = (props: Props) => {
  const headerIndices = React.useMemo(() => {
    const headerIndices: Record<string, number> = {}
    props.headers.forEach((header, index) => {
      headerIndices[header.dataKey] = index
    })
    return headerIndices
  }, [props.headers])

  const rowClass = `${ROW_CLASS} ${
    props.className ? getBlockClass(props.className, 'row') : ''
  }`
  const cellClass = `${CELL_CLASS} ${
    props.className ? getBlockClass(props.className, 'cell') : ''
  }`

  return (
    <div
      className={`${ROOT_CLASS} ${props.className ?? ''}`}
      style={getCSSWithCustomProperties({
        '--num-cols': String(props.headers.length)
      })}
    >
      <div className={rowClass}>
        {props.headers.map((header) => (
          <div className={cellClass} key={header.dataKey}>
            {header.text}
          </div>
        ))}
      </div>
      {props.rows.map((row, rowIndex) => {
        const rowKeys = getObjectKeys(row)
        const rowCells: CellData[] = Array.from(
          { length: rowKeys.length },
          () => ''
        )
        rowKeys.forEach((key) => {
          const index = headerIndices[key]
          rowCells[index] = row[key]
        })
        return (
          <div className={rowClass} key={`row-${rowIndex}`}>
            {rowCells.map((cell, cellIndex) => (
              <div className={cellClass} key={`cell-${cellIndex}`}>
                {cell}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}
