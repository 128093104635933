import * as React from 'react'
import { PermitHolderResponse } from 'src/api/charitable-gaming/api-response-types'
import { getPermitHolderSearchUrl } from 'src/api/charitable-gaming/constants'
import { useFetch } from './use-fetch'

export const PermitHolderNoResultsError = 'Permit not found'

export const useFetchPermitHolder = () => {
  const [fetched, setFetched] = React.useState<boolean>(false)
  const { handleFetch, data, error, loading, reset } =
    useFetch<PermitHolderResponse>('GET')

  const handleSearch = async (permitId?: string) => {
    if (!permitId) {
      reset()
      setFetched(false)
      return
    }
    setFetched(false)
    const results = await handleFetch(getPermitHolderSearchUrl(permitId))
    setFetched(true)
    return results
  }

  return {
    loading,
    data,
    error,
    fetched,
    noResults: !loading && error === PermitHolderNoResultsError,
    handleSearch
  }
}
