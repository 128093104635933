import { RootState } from '../store'

const getPermitHolderState = (state: RootState) => state.permitHolder
const getActivePermitHolder = (state: RootState) =>
  state.permitHolder.permitHolder
const getActivePermitHolderType = (state: RootState) =>
  state.permitHolder.formType

export const PermitHolderSelectors = {
  getActivePermitHolder: getActivePermitHolder,
  getActiveFormType: getActivePermitHolderType,
  state: getPermitHolderState
}
