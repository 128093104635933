import * as React from 'react'
import { getModifierClass, joinClasses } from 'src/helpers/components'
import './styles.scss'

type TextAreaElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

export type Props = {
  className?: string
  placeholder?: string
  error?: boolean
} & TextAreaElementProps

const ROOT_CLASS = 'input-textarea'

export const InputTextarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const { className, error, placeholder, ...rest } = props
    return (
      <textarea
        {...rest}
        className={joinClasses([
          ROOT_CLASS,
          className,
          getModifierClass(ROOT_CLASS, 'error', error === true)
        ])}
        ref={ref}
        placeholder={placeholder ?? 'Begin typing…'}
      />
    )
  }
)
