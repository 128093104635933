import * as React from 'react'
import { NoResultsTable } from 'src/components/no-results-table/no-results-table-ui'
import { UnsubmittedForm } from 'src/api/charitable-gaming/api-response-types'
import { Breadcrumbs } from 'src/components/breadcrumb/constants'
import { ErrorMessage } from 'src/components/error-message/error-message-ui'
import { LicenseeSearchBox } from 'src/components/licensee-search-box/licensee-search-box-ui'
import { LicenseeSearchResults } from 'src/components/licensee-search-results/licensee-search-results-ui'
import { LoadingIndicator } from 'src/components/loading-indicator/loading-indicator-ui'
import { Page } from 'src/components/page/page-ui'
import { TableBlank } from 'src/components/table-blank/table-blank-ui'
import { getBlockClass } from 'src/helpers/components'
import './styles.scss'

const ROOT_CLASS = 'licensee-search-page'
const NO_RESULTS_CLASS = getBlockClass(ROOT_CLASS, 'no-results')

export type Props = {
  onSearch: (searchTerm?: string) => Promise<void>
  onContinueClick: (form: UnsubmittedForm) => void
  defaultSearchTerm?: string
  loading: boolean
  noResults: boolean
  noFormsReady: boolean
  forms: UnsubmittedForm[]
  error?: string
}

const LicenseeSearchPageContents = (props: Props) => {
  if (props.loading) {
    return <LoadingIndicator />
  } else if (props.error) {
    return <ErrorMessage message={props.error} />
  } else if (props.noResults) {
    return <NoResultsTable />
  } else if (props.forms.length) {
    return (
      <LicenseeSearchResults
        forms={props.forms}
        onContinueClick={props.onContinueClick}
      />
    )
  } else if (props.noFormsReady) {
    return (
      <TableBlank>
        <p className={NO_RESULTS_CLASS}>
          No new forms are ready to be submitted for this organization.
        </p>
      </TableBlank>
    )
  }
  return null
}

export const LicenseeSearchPage = (props: Props) => {
  return (
    <Page
      headerText={'Licensee Search'}
      className={ROOT_CLASS}
      breadcrumb={Breadcrumbs.licenseeSearch}
    >
      <section className={getBlockClass(ROOT_CLASS, 'contents')}>
        <LicenseeSearchBox
          onSearch={props.onSearch}
          defaultSearchTerm={props.defaultSearchTerm}
        />
        <LicenseeSearchPageContents {...props} />
      </section>
    </Page>
  )
}
