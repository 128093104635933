import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/helpers/constants'
import { ReportSubmitted, ReportSubmittedProps } from './report-submitted-ui'

type ReportSubmittedType =
  | 'permitHolderRaffle'
  | 'licenseeSearch'
  | 'permitHolderCasino'

export type ReportSubmittedContainerProps = Omit<
  ReportSubmittedProps,
  'onButtonClick'
> & {
  type: ReportSubmittedType
}

const getRoute = (type: ReportSubmittedType) => {
  switch (type) {
    case 'licenseeSearch':
      return ROUTES.licenseeSearch
    case 'permitHolderCasino':
      return ROUTES.permitHolderSearchCasino
    case 'permitHolderRaffle':
      return ROUTES.permitHolderSearchRaffle
    default:
      return undefined
  }
}

export const ReportSubmittedContainer: React.FC<
  ReportSubmittedContainerProps
> = (props) => {
  const { type, ...uiProps } = props
  const navigate = useNavigate()

  const onButtonClick = () => {
    const route = getRoute(type)
    route && navigate(route)
  }
  return <ReportSubmitted {...uiProps} onButtonClick={onButtonClick} />
}
