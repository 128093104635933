import * as React from 'react'
import { WeeklyBeanoFormPrintProps } from '@interaction-gaming/mslc-charitable-gaming-components'
import dateUtil from 'src/services/dayjs'
import { WeeklyBeanoPrintLink } from './weekly-beano-print-link-ui'
import { ReportSubmittedContainer } from 'src/components/report-submitted/report-submitted-container'
import { DayFormat } from 'src/services/dayjs/constants'
import './styles.scss'

type NoReportProps = {
  fullForm: false
}

type FullFormProps = {
  fullForm: true
} & WeeklyBeanoFormPrintProps

export type Props = {
  occasionDate: string
} & (NoReportProps | FullFormProps)

const ROOT_CLASS = 'weekly-beano-report-submitted'

export const WeeklyBeanoReportSubmitted = (props: Props) => {
  const baseText = `Your report for the week of ${dateUtil
    .tz(props.occasionDate)
    .format(DayFormat.monthDayYearDoubleDigit)} has been submitted.`
  return (
    <ReportSubmittedContainer
      type="licenseeSearch"
      buttonText="File Another Week"
    >
      <div className={ROOT_CLASS}>
        {baseText}
        {props.fullForm && (
          <>
            {' '}
            Click <WeeklyBeanoPrintLink {...props} /> to print a copy of this
            form for your files.
          </>
        )}
      </div>
    </ReportSubmittedContainer>
  )
}
